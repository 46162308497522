
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Login extends Vue {
/** data */
  userId: string = '';
  password: string = '';
  rememberMe = false;
  isShowTimeout = false;
  csrfToken = '';
  /** mounted */
  // string
  mounted(): void {
    // URL パラメータを取得 timeoutの場合はタイムアウトを表示
    // eslint-disable-next-line no-restricted-globals
    const pair: string[] = window.location.search.substring(1).split('&');
    for (let i = 0; pair[i]; i += 1) {
      if (pair[i] === 'timeout') {
        this.isShowTimeout = true;
      }
    }

    // CSRFトークンをポストパラメータとしてフォームにセット
    this.csrfToken = this.getCsrfTokenByCookie();
    // this.userId = '';
    // this.password = '';
    // return 'mounted';
    // location.search.substring(1).split('&').forEach((param) => {
  }
  /**
   * CSRFトークンをクッキーから取得する
   */
  getCsrfTokenByCookie(): string {
    // eslint-disable-next-line no-useless-escape
    const cookie: string = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    return cookie;
  }
}

